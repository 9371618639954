import React, { useState, useEffect } from 'react';
import './styles/twelweth.css';

const getDataFromServer = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    throw error;
  }
};

const postDataToServer = async (token, data) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
  } catch (error) {
    console.error("Failed to post data:", error);
  }
};

const TwelwethPage = ({ onNext, onPrevious  }) => {
  const [interests, setInterests] = useState([
    "Eläimet",
    "Elokuvat ja sarjat",
    "Luonto ja retkeily",
    "Lukeminen ja kirjoittaminen",
    "Matkustaminen",
    "Musiikki",
    "Muoti",
    "Perhe ja läheiset",
    "Politiikka",
    "Taide ja käsityöt",
    "Terveys ja liikunta",
    "Tiede ja teknologia",
    "Videopelit",
    "Vieraskulttuurit"
    
  ]);
  const [selectedInterests, setSelectedInterests] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      getDataFromServer(token).then(data => {
        if (data && data.MielenkiinnonKohteet && data.MielenkiinnonKohteet.length > 0) {
          setSelectedInterests(data.MielenkiinnonKohteet);
        }
      }).catch(error => {
        console.error("Failed to load data:", error);
      });
    }
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedInterests([...selectedInterests, value]);
    } else {
      setSelectedInterests(selectedInterests.filter((interest) => interest !== value));
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      await postDataToServer(token, { MielenkiinnonKohteet: selectedInterests });
    }
  };

  return (
  <div className="twelfth-page-wrapper">
    <h1>Mielenkiinnon kohteet</h1>
    <ul className="checkbox-group">
      {interests.map((interest, index) => (
        <li key={index} className="checkbox-item">
          <label className="checkbox-label" htmlFor={`interest_${index}`}>
            <span className="checkbox-box">
              <input
                type="checkbox"
                id={`interest_${index}`}
                name={`interest_${index}`}
                value={interest}
                checked={selectedInterests.includes(interest)}
                onChange={handleCheckboxChange}
              />
            </span>
            {interest}
          </label>
        </li>
      ))}
    </ul>
    <div className="button-group12">
      <button onClick={() => { handleSubmit(); onPrevious(); }}>Edellinen</button>
      <button onClick={() => { handleSubmit(); onNext(); }}>Seuraava</button>
    </div>
  </div>
);

};


export default TwelwethPage;
