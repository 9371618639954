import React, { useState } from 'react';
import './App.css'; // Import general styles
import './register.css'; // Import input styles
import { useNavigate } from 'react-router-dom';

function Register() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }


    const url = "https://pretti-207c14282d6e.herokuapp.com/users/register";

    const payload = {
      name: name,
      surname: surname,
      email: email,
      password: password,
      id: "asdfsadasdfasdfasdfasasdfdffsadf123asdf24234"
    };

    try {
    
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      navigate('/login'); // Redirect to the /login page after successful registration
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const goToLogin = () => {
    navigate('/login'); // Redirect to the /register page
  };

  return (
    <div className="App">
      <div className="register-container">
        <h2>Tervetuloa</h2>
        <p className="description">Varaa aikaa profiilin luomiseen noin 15-30 minuuttia. Kaikki ennen 1.12. rekisteröityneet ja kokonaisen profiilin luoneet saavat Belan maksutta käyttöönsä toistaiseksi. </p>
        <h3>Rekisteröidy</h3>
        <input
          type="text"
          placeholder="Nimi"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Sukunimi"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
        <input
          type="email"
          placeholder="Sähköposti"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Salasana"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Vahvista salasana"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
         <div class="button-container">
    <button onClick={handleRegister} type="button">Rekisteröidy</button>
    <button onClick={goToLogin} type="button">Onko jo tili?</button>
  </div>
      </div>
    </div>
  );
}

export default Register;
